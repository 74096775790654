// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  env: 'qa',
  baseUrl: 'https://api.qa.belender.net',
  domainUrl: 'https://backoffice.qa.belender.net',
  widgetUrl: 'https://widget.qa.belender.net'
};


